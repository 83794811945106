<template>
	<div>
		<template v-if="$store.state.userInfo.userType == 2 ">
		<!-- <div class="page-top-box"> -->
		    <el-tabs v-model="activeName" @tab-click="clickEvent">
		        <el-tab-pane label="年龄段分布" name="1"></el-tab-pane>
		        <el-tab-pane label="性别分布" name="2"></el-tab-pane>
		        <el-tab-pane label="城市分布" name="3"></el-tab-pane>				
		    </el-tabs>
		<!-- </div> -->
		<div class="page-content-box" >
			
			<div id="ageChart" v-show="v1"></div>
			<div id="sexChart" v-show="v2"></div>
			<div id="cityChart" v-show="v3"></div>
			<div class="page-list-box" v-show="v1">
			    <el-table
			        :data="dataList"
			        header-row-class-name="page-list-table"
			        style="width: 100%"
			        v-loading="listLoading"
			        size="small"
			        stripe
			    >
			    	<el-table-column prop="name" label="年龄段"></el-table-column>
			    	<el-table-column prop="sort" label="人数占比">
						<template slot-scope="scope">{{(scope.row.sort * 100).toFixed(2)}}%</template>
					</el-table-column>
			    			
			    </el-table>
			</div>
			<div class="page-list-box" v-show="v2">
			    <el-table			       
			       
			        style="width: 100%"
			        v-loading="listLoading"
			        size="small"					
					:header-cell-style="{ 'text-align': 'center' }"
					:cell-style="{ 'text-align': 'left' }"
			        :data="dataList"
			    >
			    	<el-table-column prop="name" ></el-table-column>
			    	<el-table-column prop="sort" >
						<template slot-scope="scope">{{(scope.row.sort * 100).toFixed(2)}}%</template>
					</el-table-column>
			    			
			    </el-table>
			</div>
			
			<div class="page-list-box" v-show="v3">
				<div class="markRed" v-show="v3">注意：仅按用户最近登录城市进行统计，且以上图表最多展示占比最多的前10个城市</div>
			    <el-table
			        :data="dataList"
			        header-row-class-name="page-list-table"
			        style="width: 100%"
			        v-loading="listLoading"
			        size="small"
			        stripe
			    >
			    	<el-table-column prop="name" label="城市"></el-table-column>
			    	<el-table-column prop="sort" label="人数占比">
						<template slot-scope="scope">{{(scope.row.sort * 100).toFixed(2)}}%</template>
					</el-table-column>
			    			
			    </el-table>
			</div>
						
		</div>
		</template>
		<template v-else>
			<div class="visit-box">
			    <div>					
			        <img src="@/assets/img/empty.png"/>
			        <p>非艺人用户，粉丝画像稍后提供！</p>
			    </div>
			</div>
		</template>
	</div>
</template>
<script>
import { Chart } from '@antv/g2';
export default {
    components: { },
    name: "dashboard",
    data() {
        return {
            dataLoading: false,
			listLoading: false,
            //interval: [],
            activeName: '1',			
			dataChart:null,			
			dataList: null,			
			chart1: null,
			chart2: null,
			chart3: null,
			v1:true,
			v2:false,
			v3:false,
			currentPage2: 5,
			pageSize: 10,  // 每页显示的行数
        };
    },
    computed:{
        
    },
    created(){
		if(this.$store.state.userInfo.userType == 2 ){
			this.getdataFan();
		}
    },
    methods:{
        //获取粉丝增长数据 线图
        getdataFan(){			
            this.$request({
                url: "/portrait/getInfo",
                method: "GET",
            })
			.then((res) => {
				const { state, msg, result } = res.data;
				
				if (state == 1 && result) {
					if( this.activeName == 1 ){
						this.dataChart = result.ageData
						this.dataList = result.ageData
						
						this.v2 = false
						this.v3 = false
						this.v1 = true
					}
					if( this.activeName == 2 ){
						this.dataChart = result.sexData
						this.dataList = result.sexData
						
						this.v1 = false
						this.v3 = false
						this.v2 = true
					}
					if( this.activeName == 3 ){
						this.dataChart = result.cityData
						this.dataList = result.cityData
						this.v1 = false
						this.v2 = false
						this.v3 = true
					}
					this.$nextTick(() => {
						this.drawChart();
						this.drawChart1();	
						this.drawChart2();	
					})
					
					
				} else {
					this.$message.error(msg || "请求失败");
				}
			})
			.catch(() => {
				this.$message.error("请求错误");
			})
			.finally(() => {
				this.dataLoading = false;
			});
        },
        clickEvent(tab){
			
            this.getdataFan();
        },
			
		//年龄
		drawChart(){
		    if( this.chart1 ) this.chart1.destroy();
		    let _data = [...this.dataChart];
		    _data.map( v => {
		        v.sort = parseFloat(v.sort);
		    })
			
		    this.chart1 = new Chart({
		        container:'ageChart',
		        autoFit: true,
		        height: 300,
		    });
		    this.chart1.data(_data);
			
		    this.chart1.interaction('active-region');    
		
		    this.chart1.tooltip({
		        showTitle: false,
		        showMarkers: false,
		    });
		    this.chart1
		        .interval()
		        .position('name*sort')
		        .size(30)
		        .tooltip('name*sort*percent', (name, sort, percent) => {
		            percent = (percent * 100).toFixed(2) + '%';
		            return {
		                name: name,
		                value: percent,
		            };
		        });
		    this.chart1.render();
		},
		//性别
		drawChart1(){
		    if( this.chart2 ) this.chart2.destroy();
			
		    let _data = [];
		    _data = [...this.dataChart];
			_data.map( v => {
			    v.percent = v.sort
			})
			_data = _data.filter( v => v.sort > 0 );
			
			
		    this.chart2 = new Chart({
		        container: 'sexChart',
		        autoFit: true,
		        height: 420,				
		    });
			this.chart2.coordinate('theta', {
			    radius: 0.6,
			});
			
		    this.chart2.data(_data);
			this.chart2.scale('percent', {
			    formatter: (val) => {
			        val = (val * 100).toFixed(2) + '%';
			        return val;
			    },
			});
			
			this.chart2.tooltip({
			    showTitle: false,
			    showMarkers: false,
			});
			
			this.chart2.legend('name', {
			    position: 'top',
			});
			this.chart2
			    .interval()
			    .adjust('stack')
			    .position('percent')
			    .color('name')
			    .label('percent', (val) => {
			        return {
			            // offset: val > 0.05 ? -30 : null,
			            content: (obj) => {
			                return obj.name //+ ':' + (obj.percent * 100).toFixed(2) + '%';
			            },
			        };
			    })
			    .tooltip('name*sort*percent', (name, sort, percent) => {
			        percent = (percent * 100).toFixed(2) + '%';
			        return {
			            name: name,
			            value: percent,
			        };
			    });
			
			
			this.chart2.render();
		},
		//城市
		drawChart2(){
		    if( this.chart3 ) this.chart3.destroy();
		    let _data = [...this.dataChart].slice(0,10);
			_data.map( v => {
			    v.value =  (v.sort * 100).toFixed(2) + '%'
			})
		    this.chart3 = new Chart({
		        container:'cityChart',
		        autoFit: true,
		        height: 500,
		    });
			_data.reverse();
			
		    this.chart3.data(_data);
			
			this.chart3.legend(false);
		    this.chart3.coordinate().transpose();
			this.chart3.tooltip({
			    showTitle: true,
			    showMarkers: false,
			    showCrosshairs: true,
			});
			
			this.chart3
			  .interval()
			  .position('name*sort')
			  .size(26)
			  .label('value', {
			    style: {
			      fill: '#8d8d8d',
			    },
			    offset: 10,
			  })
			  .tooltip('name*sort*percent', (name, sort, percent) => {
				   percent = (percent * 100).toFixed(2) + '%';
			      return {
			          name: '占比',
			          value: percent,
			      };
			  })
			//this.chart3.interaction('element-active');
			
		    this.chart3.render();
		},
    }
};
</script>

<style lang="scss" scoped>
.visit-box{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
	font-size: 24px;
	text-align: center;
    img{
		margin-top: 150px;
       
    }
}
.page-list-box{
	margin-top: 40px;
}
.data-group{
    > ::v-deep .el-card__header{
        font-size: 16px;
        line-height: 22px;
        a{
            font-size: 14px;
        }
    }
}
#fansChart{
	margin-bottom: 40px;
}
.markRed{
	padding: 0 0 40px 0;
	color: #f00;
}
</style>